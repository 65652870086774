import React, { useState, useEffect } from "react"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import "../../css/city.css"
import { UpdateOneCity, AddOneCity } from "../../common/Services/dbServices"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"

const AddCity = ({
  setOpen,
  edit,
  specificCity = null,
  refetch,
  setShowSuccessAdded,
  setShowError,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(" Name is required"),
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const addNewCity = async data => {
    if (edit) {
      setIsLoading(true)
      try {
        const result = await UpdateOneCity({
          item: specificCity,
          data,
        })
        if (result) {
          setOpen(false)
          setIsLoading(false)
          refetch()
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)
        }
      } catch (error) {
        setIsLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    } else {
      setIsLoading(true)
      try {
        const result = await AddOneCity({
          data,
        })
        if (result) {
          setOpen(false)
          setIsLoading(false)
          refetch()
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)
        }
      } catch (error) {
        setIsLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    }
  }

  useEffect(() => {
    setValue("name", specificCity?.name)
  }, [])
  return (
    <div>
      <form>
        <div className="city-text-field">
          <div className="city-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              {...register("name", { required: true })}
              error={errors.name ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.name && errors.name?.message
                ? errors.name?.message.toString()
                : null}
            </Typography>
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewCity)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default AddCity
