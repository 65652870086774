import React, { useEffect, useState } from "react"
import "../../css/revenue.css"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"
import { useTheme } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"
import TableHead from "@mui/material/TableHead"
import { Modal } from "@mui/material"
// import {
//   useBranchesSearch,
//   useCurrentProvider,
//   useOrdersBtwDates,
//   useOrdersThisYear,
//   useOrdersThisMonth,
//   useOrdersThisWeek,
//   useOrdersTodays,
// } from "../../../Common/Hooks/queryCalls";

import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import moment from "moment"
import { LottieLoading } from "../../common/LottieLoading"
import CircularProgress from "@mui/material/CircularProgress"
import { formatDate, formatCurrency } from "../../common/Services/functions"
import RevenueOrderDetails from "./RevenueOrderDetails"
import {
  useProviderSearch,
  useOrdersBtwDates,
  useOrdersThisYear,
  useOrdersThisMonth,
  useOrdersThisWeek,
  useOrdersTodays,
} from "../../common/Hooks/queryCalls"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

const Revenue = () => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [specificOrder, setSpecificOrder] = useState({})
  const [btwDate, setBtwDate] = useState(null)
  const [fetch, setFetch] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [value, setValue] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [selectedProvider, setSelectedProvider] = useState(null)
  // const [isBranchSelected, setIsBranchSelected] = useState(false)

  // const currentProvider = useCurrentProvider()
  const { data, isLoading, refetch } = useProviderSearch()
  console.log("branches::", data)
  const [currentYear, setCurrentYear] = useState(moment().format("yyyy"))
  const [currentMonth, setCurrentMonth] = useState(moment())
  const startOfDay = moment(startDate).startOf("day").toDate()
  const endOfDay = moment(endDate).endOf("day").toDate()
  const startOfYear = moment(currentYear).startOf("year").toDate()
  const endOfYear = moment(currentYear).endOf("year").toDate()
  const startOfMonth = moment(currentMonth).startOf("month").toDate()
  const endOfMonth = moment(currentMonth).endOf("month").toDate()
  const startOfWeek = moment(currentMonth).startOf("isoweek").toDate()
  const endOfWeek = moment(currentMonth).endOf("isoweek").toDate()
  const startTodays = moment(currentMonth).startOf("day").toDate()
  const endTodays = moment(currentMonth).endOf("day").toDate()
  // console.log("momentDate:", startOfDay, endOfDay);
  // console.log("currentYear", currentYear);
  // console.log("momentYear:", startOfYear, endOfYear);
  // console.log("currentMonth", currentMonth);
  // console.log("momentMonth:", startOfMonth, endOfMonth);
  // console.log("momentweek:", startOfWeek, endOfWeek);
  // console.log("todays:", startTodays, endTodays);

  const btwdates = useOrdersBtwDates({
    startDate: startOfDay & (startOfDay !== "Invalid Date") ? startOfDay : null,
    endDate: endOfDay & (endOfDay !== "Invalid Date") ? endOfDay : null,
    providerId: selectedProvider,
  })

  // console.log("btwdates:", btwdates);

  const thisYear = useOrdersThisYear({
    startOfYear,
    endOfYear,
    providerId: selectedProvider,
  })

  // console.log("thisYear:", thisYear);

  const thisMonth = useOrdersThisMonth({
    startOfMonth,
    endOfMonth,
    providerId: selectedProvider,
  })

  // console.log("thisMonth:", thisMonth);

  const thisWeek = useOrdersThisWeek({
    startOfWeek,
    endOfWeek,
    providerId: selectedProvider,
  })

  // console.log("thisWeek:", thisWeek);

  const thisTodays = useOrdersTodays({
    startTodays,
    endTodays,
    providerId: selectedProvider,
  })

  // console.log("thisTodays:", thisTodays);

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  // if (data?.length == 0) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       No item Available
  //     </h1>
  //   );
  // }
  const handleOrdersBtwDates = () => {}
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - btwdates?.data?.length)
      : 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const handleStartDateChange = event => {
    setStartDate(event.target.value)
  }

  const handleEndDateChange = event => {
    setEndDate(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
    setSpecificOrder({})
  }

  const getTotalAmountThisWeek = () => {
    const sum = thisWeek?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }
  const getTotalAmountTodays = () => {
    const sum = thisTodays?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }
  const getTotalAmountThisMonth = () => {
    const sum = thisMonth?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }
  const getTotalAmountThisYear = () => {
    const sum = thisYear?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }

  console.log("selectedProvider:", selectedProvider)
  console.log("providerSWE:", typeof data)

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
        >
          <RevenueOrderDetails
            setOpen={setOpen}
            specificOrder={specificOrder}
          />
        </Box>
      </Modal>
      <div style={{ marginTop: "35px" }}>
        <div style={{ marginTop: "35px" }} className="revenue-header">
          <Autocomplete
            autoComplete
            includeInputInList
            id="combo-box-demo"
            options={data || []}
            size="small"
            fontSize="0.6rem"
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.6rem",
                minWidth: 190,
                display: "flex",
                justifyContent: "center",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.8rem",
                minWidth: 230,
                display: "flex",
                justifyContent: "center",
                width: 300,
              },
              "@media (min-width: 970px)": {
                fontSize: "0.8rem",
                width: "350px",
                display: "flex",
                justifyContent: "center",
              },
            }}
            onChange={(event, newValue) => {
              // console.log("new::", newValue);
              if (newValue && newValue.id) {
                setSelectedProvider(newValue.id)
                // setIsBranchSelected(true)
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Select Provider"
                variant="outlined"
                // onChange={(newValue) => {
                //   searchBranch(newValue.target.value);
                // }}
              />
            )}
          />
          <Button
            sx={buttonStyle}
            variant="contained"
            style={{ fontFamily: "outfit" }}
          >
            Filter
          </Button>
          <Button
            sx={buttonStyle}
            variant="contained"
            style={{ fontFamily: "outfit" }}
          >
            Clear
          </Button>
        </div>
        <div className="revenue-card-div">
          <div className="card-div">
            {" "}
            <Card variant="outlined" sx={{ backgroundColor: "#488A99" }}>
              <CardContent>
                <div className="card-text-div">
                  <div className="card-text-div-row">
                    <h4 style={{ color: "white" }}>Todays Revenue</h4>
                    <h4 style={{ color: "white" }}>
                      {" "}
                      {formatCurrency(getTotalAmountTodays() || 0)}
                    </h4>
                  </div>
                  <div className="card-text-div-row">
                    <h4 style={{ color: "white" }}>Total Orders</h4>
                    <h4 style={{ color: "white" }}>
                      {" "}
                      {thisTodays?.data?.length || 0}
                    </h4>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="card-div">
            {" "}
            <Card variant="outlined" sx={{ backgroundColor: "#6AB187" }}>
              <CardContent>
                <div className="card-text-div">
                  <div className="card-text-div-row">
                    <h4 style={{ color: "white" }}>This Week</h4>
                    <h4 style={{ color: "white" }}>
                      {formatCurrency(getTotalAmountThisWeek() || 0)}
                    </h4>
                  </div>
                  <div className="card-text-div-row">
                    <h4 style={{ color: "white" }}>Total Orders</h4>
                    <h4 style={{ color: "white" }}>
                      {thisWeek?.data?.length || 0}
                    </h4>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="card-div">
            {" "}
            <Card variant="outlined" sx={{ backgroundColor: "#DBAE58" }}>
              <CardContent>
                <div className="card-text-div">
                  <div className="card-text-div-row">
                    <h4 style={{ color: "white" }}>This Month</h4>
                    <h4 style={{ color: "white" }}>
                      {" "}
                      {formatCurrency(getTotalAmountThisMonth() || 0)}
                    </h4>
                  </div>
                  <div className="card-text-div-row">
                    <h4 style={{ color: "white" }}>Total Orders</h4>
                    <h4 style={{ color: "white" }}>
                      {" "}
                      {thisMonth?.data?.length || 0}
                    </h4>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="card-div">
            {" "}
            <Card variant="outlined" sx={{ backgroundColor: "#7E909A" }}>
              <CardContent>
                <div className="card-text-div">
                  <div className="card-text-div-row">
                    <h4 style={{ color: "white" }}>This year</h4>
                    <h4 style={{ color: "white" }}>
                      {" "}
                      {formatCurrency(getTotalAmountThisYear() || 0)}
                    </h4>
                  </div>
                  <div className="card-text-div-row">
                    <h4 style={{ color: "white" }}>Total Orders</h4>
                    <h4 style={{ color: "white" }}>
                      {" "}
                      {thisYear?.data?.length || 0}
                    </h4>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="tabs-div">
          <Box sx={{ bgcolor: "background.paper", width: "100%", mt: 4 }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="full width tabs example"
              >
                <Tab
                  sx={tableHeadStyle}
                  label="Todays Order"
                  {...a11yProps(0)}
                  style={{ fontFamily: "outfit" }}
                />
                <Tab
                  sx={tableHeadStyle}
                  label="This Weeks Order"
                  {...a11yProps(1)}
                  style={{ fontFamily: "outfit" }}
                />
                <Tab
                  sx={tableHeadStyle}
                  label="This Months Order"
                  {...a11yProps(2)}
                  style={{ fontFamily: "outfit" }}
                />
                <Tab
                  sx={tableHeadStyle}
                  label="This Years Order"
                  {...a11yProps(3)}
                  style={{ fontFamily: "outfit" }}
                />
                <Tab
                  sx={tableHeadStyle}
                  label="Between Dates"
                  {...a11yProps(4)}
                  style={{ fontFamily: "outfit" }}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <div>
                  <div className="exports-btn">
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      style={{ fontFamily: "outfit" }}
                    >
                      Export
                    </Button>
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      style={{ fontFamily: "outfit" }}
                    >
                      Email
                    </Button>
                  </div>
                  {/* {isBranchSelected ? ( */}
                  <div className="table-div">
                    <TableContainer component={Paper}>
                      <Table sx={{}} aria-label="custom pagination table">
                        {thisTodays?.data?.length == 0 && (
                          <caption style={{ fontFamily: "outfit" }}>
                            No Items Available
                          </caption>
                        )}
                        <TableHead>
                          <TableRow>
                            <TableCell sx={tableHeadStyle}>Reference</TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Customer
                            </TableCell>

                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Amount
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Details
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? thisTodays?.data?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : thisTodays?.data
                          )?.map(row => (
                            <TableRow key={row._id}>
                              <TableCell
                                sx={tableContentStyle}
                                component="th"
                                scope="row"
                                style={{ fontFamily: "outfit" }}
                              >
                                {row?.refNumber}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatDate(row?.date)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >{`${row?.customer?.firstName} ${row?.customer?.lastName} (+91 ${row?.customer?.number})`}</TableCell>

                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatCurrency(row?.amount)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                <Button
                                  sx={buttonStyle}
                                  onClick={() => {
                                    setOpen(true)
                                    setSpecificOrder(row)
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  Details
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              style={{ fontFamily: "outfit" }}
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: -1 },
                              ]}
                              colSpan={5}
                              count={thisTodays?.data?.length || 0}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                  {/* ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress sx={{ color: "#2b445a" }} />
                    <h6>Please select the Branch!..</h6>
                  </Box>
                )} */}
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <div>
                  <div className="exports-btn">
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      style={{ fontFamily: "outfit" }}
                    >
                      Export
                    </Button>
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      style={{ fontFamily: "outfit" }}
                    >
                      Email
                    </Button>
                  </div>
                  {/* {isBranchSelected ? ( */}
                  <div className="table-div">
                    <TableContainer component={Paper}>
                      <Table sx={{}} aria-label="custom pagination table">
                        {thisWeek?.data?.length == 0 && (
                          <caption style={{ fontFamily: "outfit" }}>
                            No Items Available
                          </caption>
                        )}
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={tableHeadStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              Reference
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Customer
                            </TableCell>

                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Amount
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Details
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? thisWeek?.data?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : thisWeek?.data
                          )?.map(row => (
                            <TableRow key={row._id}>
                              <TableCell
                                sx={tableContentStyle}
                                component="th"
                                scope="row"
                                style={{ fontFamily: "outfit" }}
                              >
                                {row?.refNumber}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatDate(row?.date)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >{`${row?.customer?.firstName} ${row?.customer?.lastName} (+91 ${row?.customer?.number})`}</TableCell>

                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatCurrency(row?.amount)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                <Button
                                  sx={buttonStyle}
                                  onClick={() => {
                                    setOpen(true)
                                    setSpecificOrder(row)
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  Details
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              style={{ fontFamily: "outfit" }}
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: -1 },
                              ]}
                              colSpan={5}
                              count={thisWeek?.data?.length || 0}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <div>
                  <div className="exports-btn">
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      style={{ fontFamily: "outfit" }}
                    >
                      Export
                    </Button>
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      style={{ fontFamily: "outfit" }}
                    >
                      Email
                    </Button>
                  </div>
                  {/* {isBranchSelected ? ( */}
                  <div className="table-div">
                    <TableContainer component={Paper}>
                      <Table sx={{}} aria-label="custom pagination table">
                        {thisMonth?.data?.length == 0 && (
                          <caption style={{ fontFamily: "outfit" }}>
                            No Items Available
                          </caption>
                        )}
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={tableHeadStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              Reference
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Customer
                            </TableCell>

                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Amount
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Details
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? thisMonth?.data?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : thisMonth?.data
                          )?.map(row => (
                            <TableRow key={row._id}>
                              <TableCell
                                sx={tableContentStyle}
                                component="th"
                                scope="row"
                                style={{ fontFamily: "outfit" }}
                              >
                                {row?.refNumber}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatDate(row?.date)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >{`${row?.customer?.firstName} ${row?.customer?.lastName} (+91 ${row?.customer?.number})`}</TableCell>

                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatCurrency(row?.amount)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                <Button
                                  sx={buttonStyle}
                                  onClick={() => {
                                    setOpen(true)
                                    setSpecificOrder(row)
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  Details
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              style={{ fontFamily: "outfit" }}
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: -1 },
                              ]}
                              colSpan={5}
                              count={thisMonth?.data?.length || 0}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <div>
                  <div className="exports-btn">
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      style={{ fontFamily: "outfit" }}
                    >
                      Export
                    </Button>
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      style={{ fontFamily: "outfit" }}
                    >
                      Email
                    </Button>
                  </div>
                  {/* {isBranchSelected ? ( */}
                  <div className="table-div">
                    <TableContainer component={Paper}>
                      <Table sx={{}} aria-label="custom pagination table">
                        {thisYear?.data?.length == 0 && (
                          <caption style={{ fontFamily: "outfit" }}>
                            No Items Available
                          </caption>
                        )}
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={tableHeadStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              Reference
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Customer
                            </TableCell>

                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Amount
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Details
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? thisYear?.data?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : thisYear?.data
                          )?.map(row => (
                            <TableRow key={row._id}>
                              <TableCell
                                sx={tableContentStyle}
                                component="th"
                                scope="row"
                                style={{ fontFamily: "outfit" }}
                              >
                                {row?.refNumber}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatDate(row?.date)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >{`${row?.customer?.firstName} ${row?.customer?.lastName} (+91 ${row?.customer?.number})`}</TableCell>

                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatCurrency(row?.amount)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                <Button
                                  sx={buttonStyle}
                                  onClick={() => {
                                    setOpen(true)
                                    setSpecificOrder(row)
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  Details
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              style={{ fontFamily: "outfit" }}
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: -1 },
                              ]}
                              colSpan={5}
                              count={thisYear?.data?.length || 0}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={4} dir={theme.direction}>
                <div>
                  <div className="exports-btn">
                    {" "}
                    <div className="date-input-div">
                      <label style={{ fontFamily: "outfit" }}>
                        Start Date:
                        <input
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          className="date-input"
                        />
                      </label>
                      <br />
                      <label style={{ fontFamily: "outfit" }}>
                        End Date:
                        <input
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          className="date-input"
                        />
                      </label>{" "}
                    </div>
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      onClick={() => setFetch(true)}
                      style={{ fontFamily: "outfit" }}
                    >
                      Filter
                    </Button>
                    <Button
                      sx={buttonStyle}
                      variant="contained"
                      onClick={() => setFetch(false)}
                      style={{ fontFamily: "outfit" }}
                    >
                      Clear
                    </Button>
                  </div>

                  <div className="table-div">
                    <TableContainer component={Paper}>
                      <Table sx={{}} aria-label="custom pagination table">
                        {btwdates?.data?.length == 0 && (
                          <caption style={{ fontFamily: "outfit" }}>
                            No Items Available
                          </caption>
                        )}
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={tableHeadStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              Reference
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Customer
                            </TableCell>

                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Amount
                            </TableCell>
                            <TableCell
                              sx={tableHeadStyle}
                              align="center"
                              style={{ fontFamily: "outfit" }}
                            >
                              Details
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? btwdates?.data?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : btwdates?.data
                          )?.map(row => (
                            <TableRow key={row._id}>
                              <TableCell
                                sx={tableContentStyle}
                                component="th"
                                scope="row"
                                style={{ fontFamily: "outfit" }}
                              >
                                {row?.refNumber}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatDate(row?.date)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >{`${row?.customer?.firstName} ${row?.customer?.lastName} (+91 ${row?.customer?.number})`}</TableCell>

                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                {formatCurrency(row?.amount)}
                              </TableCell>
                              <TableCell
                                sx={tableContentStyle}
                                align="center"
                                style={{ fontFamily: "outfit" }}
                              >
                                <Button
                                  sx={buttonStyle}
                                  onClick={() => {
                                    setOpen(true)
                                    setSpecificOrder(row)
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  Details
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              style={{ fontFamily: "outfit" }}
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: -1 },
                              ]}
                              colSpan={5}
                              count={btwdates?.data?.length || 0}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </TabPanel>
            </SwipeableViews>
          </Box>
        </div>
      </div>
    </>
  )
}

export default Revenue
const buttonStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.675rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.775rem",
  },
}
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
  },
}

const tableContentStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.65rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.75rem",
  },
}
