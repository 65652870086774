import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  FormFeedback,
  Label,
  Input,
  Alert,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

// import images
import logoSm from "../../assets/images/logo-sm.png"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { app } from "common/Services/dbServices"
const Register = props => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { user } = useSelector(state => ({
    user: state.Account.user,
  }))

  useEffect(() => {
    if (user) {
      setTimeout(() => navigate("/login"), 3000)
    }

    // setTimeout(() => {
    //     dispatch(resetRegisterFlag());
    // }, 3000);
  }, [dispatch, user, history])

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      password: "",
      phoneNumber: "",
      description: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation.validationSchema),
  })
  // // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   props.registerUser(values);
  // };

  // useEffect(() => {
  //   props.apiError("");
  // }, []);
  const userName = validation.values.username
  const email = validation.values.email
  async function onSignUp(data) {
    // await app.emailPasswordAuth.registerUser({ email, password })
    // if (registerPerson !== undefined) {
    //   try {
    //     alert("User Added Successfully")
    //     navigate("/")
    //   } catch (error) {
    //     throw error
    //   }
    // }
    // return result
  }

  document.title = "Register | Merche Admin "
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Free Register</h5>
                    <p className="text-white-50">
                      Get your free Merche account now.
                    </p>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                    {user ? (
                      <Alert
                        color="success"
                        style={{ marginTop: "13px" }}
                        className="mt-5"
                      >
                        Register User Successful
                      </Alert>
                    ) : null}
                    <Form
                      className="mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="useremail">
                          Email
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          id="useremail"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">
                          Password
                        </Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          id="userpassword"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            onClick={onSignUp}
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </div>

                      <div className="mt-2 mb-0 row">
                        {/* <div className="col-12 mt-4">
                          <p className="mb-0">
                            By registering you agree to the Veltrix{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div> */}
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Merche. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Coderythm
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
