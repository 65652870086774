import React from "react"
import { formatDate, formatCurrency } from "../../common/Services/functions"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import { Button, IconButton } from "@mui/material"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/Delete"
import EditNoteIcon from "@mui/icons-material/EditNote"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const ViewModifierDetails = ({ specificAddon }) => {
  return (
    <div className="approvals-main-div">
      <Stack spacing={2}>
        <Item>
          {" "}
          <div className="approvals-details-row">
            <div className="approvals-details-element">
              <h6>Name(EN):</h6>
              <h6>{specificAddon?.name?.en}</h6>
            </div>{" "}
            <div className="approvals-details-element">
              <h6>ProviderName(EN):</h6>
              <h6>{specificAddon?.provider?.name?.en}</h6>
            </div>
          </div>
        </Item>
        {/* <Item>
          <div className="approvals-details-row">
            <div className="approvals-details-element">
              <h6>Name(AR):</h6>
              <h6>{specificAddon?.name?.ar}</h6>
            </div>
            <div className="approvals-details-element">
              <h6>ProviderName(AR):</h6>
              <h6>{specificAddon?.provider?.name?.ar}</h6>
            </div>
          </div>
        </Item> */}
        <Item>
          {" "}
          <div className="approvals-details-row">
            <div className="approvals-details-element">
              <h6>Is Approved :</h6>
              <h6>
                {specificAddon?.isApproved ? (
                  <CheckCircleIcon />
                ) : (
                  <CancelIcon />
                )}
              </h6>
            </div>
            <div className="approvals-details-element">
              <h6>Is Active:</h6>
              <h6>
                {specificAddon?.metadata?.isActive ? (
                  <CheckCircleIcon />
                ) : (
                  <CancelIcon />
                )}
              </h6>
            </div>
          </div>
        </Item>
      </Stack>
    </div>
  )
}

export default ViewModifierDetails
