import React, { useState, useEffect } from "react"
import "../../css/orders.css"
import { formatDate, formatCurrency } from "../../common/Services/functions"
import { styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { Button, IconButton } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import {
  acceptOrder,
  cancelOrder,
  OrderStatusUpdate,
} from "../../common/Services/dbServices"
import { TextField } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"

const statusTypes = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "received",
    label: "Received",
  },
  {
    value: "accepted",
    label: "Accepted",
  },
  {
    value: "ready",
    label: "Ready",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "refunded",
    label: "Refunded",
  },
  {
    value: "completed",
    label: "Completed",
  },
]

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const OrderDetail = ({
  setOpen,
  specificOrder,
  refetch,
  setShowSuccess,
  setShowError,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  useEffect(() => {
    setStatus(specificOrder.status)
  }, [])

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedItem({})
  }
  const handleClickUpdate = async () => {
    try {
      if (status == "pending") {
        setIsLoading(true)
        const result = await OrderStatusUpdate({
          item: selectedItem,
          orderStatus: status,
        })

        if (result) {
          refetch()
          setIsLoading(false)
          setOpenDialog(false)
          setOpen(false)
          setShowSuccess(true)
          setTimeout(() => {
            setShowSuccess(false)
          }, 3000)
        }
      }
      if (status == "received") {
        setIsLoading(true)
        const result = await OrderStatusUpdate({
          item: selectedItem,
          orderStatus: status,
        })
        if (result) {
          refetch()
          setOpenDialog(false)
          setIsLoading(false)
          setOpen(false)
          setShowSuccess(true)
          setTimeout(() => {
            setShowSuccess(false)
          }, 3000)
        }
      }
      if (status == "ready") {
        setIsLoading(true)
        const result = await OrderStatusUpdate({
          item: selectedItem,
          orderStatus: status,
        })
        if (result) {
          refetch()
          setOpenDialog(false)
          setIsLoading(false)
          setOpen(false)
          setShowSuccess(true)
          setTimeout(() => {
            setShowSuccess(false)
          }, 3000)
        }
      }
      if (status == "refunded") {
        setIsLoading(true)
        const result = await OrderStatusUpdate({
          item: selectedItem,
          orderStatus: status,
        })
        if (result) {
          refetch()
          setOpenDialog(false)
          setIsLoading(false)
          setOpen(false)
          setShowSuccess(true)
          setTimeout(() => {
            setShowSuccess(false)
          }, 3000)
        }
      }
      if (status == "completed") {
        setIsLoading(true)
        const result = await OrderStatusUpdate({
          item: selectedItem,
          orderStatus: status,
        })
        if (result) {
          refetch()
          setOpenDialog(false)
          setIsLoading(false)
          setOpen(false)
          setShowSuccess(true)
          setTimeout(() => {
            setShowSuccess(false)
          }, 3000)
        }
      }
      if (status == "accepted") {
        setIsLoading(true)
        const result = await acceptOrder({
          id: selectedItem._id.toString(),
        })
        if (result) {
          refetch()
          setOpenDialog(false)
          setIsLoading(false)
          setOpen(false)
          setShowSuccess(true)
          setTimeout(() => {
            setShowSuccess(false)
          }, 3000)
        }
      }
      if (status == "cancelled") {
        setIsLoading(true)
        const result = await cancelOrder({
          id: selectedItem._id.toString(),
        })
        if (result) {
          refetch()
          setOpenDialog(false)
          setIsLoading(false)
          setOpen(false)
          setShowSuccess(true)
          setTimeout(() => {
            setShowSuccess(false)
          }, 3000)
        }
      }
      refetch()
    } catch (error) {
      setIsLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure want to Update?"}
        </DialogTitle>

        <DialogActions>
          {!isLoading ? <Button onClick={handleCloseDialog}>No</Button> : ""}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Yes
            </LoadingButton>
          ) : (
            <Button onClick={handleClickUpdate} autoFocus>
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <div className="order-main-div">
        <Stack spacing={2}>
          <Item>
            {" "}
            <div className="order-details-row">
              <div className="order-details-element">
                <h6>Order # :</h6>
                <h6>{specificOrder?.refNumber}</h6>
              </div>{" "}
              <div className="order-details-element">
                <h6>Order Status :</h6>
                <h6>
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    // label="Type*"
                    fullWidth
                    value={status}
                    onChange={newValue => {
                      setStatus(newValue.target.value)
                      // console.log(newValue.target.value);
                    }}
                  >
                    {statusTypes.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </h6>
                <h6>
                  <Button
                    variant="contained"
                    sx={tableHeadStyle}
                    onClick={() => {
                      setSelectedItem(specificOrder)
                      setOpenDialog(true)
                    }}
                    size="small"
                  >
                    Update
                  </Button>
                </h6>
              </div>
            </div>
          </Item>
          <Item>
            <div className="order-details-row">
              <div className="order-details-element">
                <h6>Date:</h6>
                <h6>{formatDate(specificOrder?.date)}</h6>
              </div>
              <div className="order-details-element">
                <h6>Customer:</h6>
                <h6>{`${specificOrder?.customer?.firstName}  ${specificOrder?.customer?.lastName}`}</h6>
              </div>
            </div>
          </Item>
          <Item>
            {" "}
            <div className="order-details-row">
              <div className="order-details-element">
                <h6>Payment Status :</h6>
                <h6>{specificOrder?.paymentStatus}</h6>
              </div>
              <div className="order-details-element">
                <h6>Provider:</h6>
                <h6>{`${specificOrder?.provider?.name?.en}`}</h6>
              </div>
            </div>
          </Item>
          <Item>
            {" "}
            <div className="order-details-row">
              <div className="order-details-element">
                <h6>Total Amount :</h6>
                <h6>{formatCurrency(specificOrder?.amount)}</h6>
              </div>
              <div className="order-details-element">
                <h6>Address:</h6>
                <h6>{`${specificOrder?.address?.firstName}  ${specificOrder?.address?.lastName}, ${specificOrder?.address?.address}`}</h6>
              </div>
            </div>
          </Item>
          <Item>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell align="right">Oty</TableCell>
                    <TableCell align="right">Addons</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {specificOrder?.items?.map(row => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.name?.en || row?.item?.name?.en}
                      </TableCell>
                      <TableCell align="right">{row?.quantity}</TableCell>
                      <TableCell align="right">
                        {row?.modifiers?.map((value, index) => (
                          // console.log(value?.modifierName?.en)
                          <h6
                            className="order-addon-h6"
                            key={index}
                          >{`${value?.modifierName?.en},`}</h6>
                        ))}
                      </TableCell>
                      <TableCell align="right">
                        {formatCurrency(row?.price || row?.item?.price)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Item>
        </Stack>
      </div>
    </>
  )
}

export default OrderDetail
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.9rem",
  },
}
