import React from "react"
import "../../css/dashboard.css"
import Orders from "../../pages/Orders/Orders"
import Approvals from "../../pages/Approvals/Approvals"
import Customers from "../../pages/Customers/Customers"
import Providers from "../../pages/Providers/Providers"
document.title = "Merche Admin"
const DashBoard = () => {
  return (
    <div
      className="dashboard-main-div"
      style={{ marginTop: "50px", paddingLeft: "10px" }}
    >
      <div className="dashboard-row">
        <h5>Last 5 Orders</h5>
        <Orders dashboard={true}/>
      </div>
      <div className="dashboard-row">
        <h5>For Approval</h5>
        <Approvals dashboard={true} />
      </div>
      <div className="dashboard-row">
        <h5>Last 5 Customers</h5>
        <Customers dashboard={true} />
      </div>
      <div className="dashboard-row">
        <h5>Last 5 Providers</h5>
        <Providers dashboard={true} />
      </div>
    </div>
  )
}

export default DashBoard
