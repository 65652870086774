import React, { useState } from "react"
import "../../css/orders.css"
import { useAllOrders } from "../../common/Hooks/queryCalls"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { Button, IconButton } from "@mui/material"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import { formatDate, formatCurrency } from "../../common/Services/functions"
import OrderDetail from "./OrderDetail"
import { LottieLoading } from "../../common/LottieLoading"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import Stack from "@mui/material/Stack"

const Orders = ({ dashboard }) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [open, setOpen] = useState(false)
  const [specificOrder, setSpecificOrder] = useState({})
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const { data, isLoading, refetch } = useAllOrders()
  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const handleClose = () => {
    setOpen(false)
    setSpecificOrder({})
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSuccess(false)
    setShowError(false)
  }
  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Order Status Changed Successfully
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
        >
          <OrderDetail
            setOpen={setOpen}
            specificOrder={specificOrder}
            refetch={refetch}
            setShowSuccess={setShowSuccess}
            setShowError={setShowError}
          />
        </Box>
      </Modal>
      <div
        style={{
          marginTop: dashboard ? "" : "50px",
          paddingLeft: dashboard ? "" : "10px",
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Customer
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Provider
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Status
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Payment
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Details
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item._id}
                      >
                        <TableCell align="center" sx={tableHeadStyle}>
                          {item?.refNumber}
                        </TableCell>
                        <TableCell align="center" sx={tableHeadStyle}>
                          {formatDate(item?.date)}
                        </TableCell>
                        <TableCell align="center" sx={tableHeadStyle}>
                          {`${item?.customer?.firstName}  ${item?.customer?.lastName}`}
                        </TableCell>

                        <TableCell align="center" sx={tableHeadStyle}>
                          {`${item?.provider?.name?.en}`}
                        </TableCell>
                        <TableCell align="center" sx={tableHeadStyle}>
                          {item?.status}
                        </TableCell>
                        <TableCell align="center" sx={tableHeadStyle}>
                          {item?.paymentStatus}
                        </TableCell>
                        <TableCell align="center" sx={tableHeadStyle}>
                          {formatCurrency(item?.amount)}
                        </TableCell>
                        <TableCell align="center" sx={tableHeadStyle}>
                          <Button
                            sx={tableHeadStyle}
                            onClick={() => {
                              setOpen(true)
                              setSpecificOrder(item)
                            }}
                          >
                            Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={tableHeadStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

export default Orders

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}
