import React, { useState } from "react"
import { useAllCity } from "../../common/Hooks/queryCalls"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { Button, IconButton } from "@mui/material"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import VisibilityIcon from "@mui/icons-material/Visibility"
import DeleteIcon from "@mui/icons-material/Delete"
import EditNoteIcon from "@mui/icons-material/EditNote"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import AddCity from "./AddCity"
import { DeleteCity } from "../../common/Services/dbServices"
import { LottieLoading } from "../../common/LottieLoading"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"

const City = () => {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [deleteItem, setDeleteItem] = useState({})
  const [showSuccessAdded, setShowSuccessAdded] = useState(false)
  const [showSuccessRemoved, setShowSuccessRemoved] = useState(false)
  const [showError, setShowError] = useState(false)
  const [specificCity, setSpecificCity] = useState({})
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { data, isLoading, refetch } = useAllCity()
  // console.log("allCity::", data);
  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setSpecificCity({})
  }

  const handleEdit = item => {
    setSpecificCity(item)
    setOpen(true)
    setEdit(true)
  }

  const handleClickDelete = async () => {
    setIsDeleteLoading(true)
    try {
      const result = await DeleteCity({
        itemId: deleteItem?._id,
      })
      if (result) {
        setOpenDialog(false)
        setIsDeleteLoading(false)
        refetch()
        setShowSuccessRemoved(true)
        setTimeout(() => {
          setShowSuccessRemoved(false)
        }, 3000)
      }
    } catch (error) {
      setIsDeleteLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDeleteItem({})
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSuccessAdded(false)
    setShowSuccessRemoved(false)
    setShowError(false)
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this city?"}
        </DialogTitle>

        <DialogActions>
          {!isDeleteLoading ? (
            <Button onClick={handleCloseDialog}>No</Button>
          ) : (
            ""
          )}
          {isDeleteLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Yes
            </LoadingButton>
          ) : (
            <Button onClick={handleClickDelete} autoFocus>
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
        >
          <AddCity
            setOpen={setOpen}
            edit={edit}
            specificCity={specificCity}
            refetch={refetch}
            setShowSuccessAdded={setShowSuccessAdded}
            setShowError={setShowError}
          />
        </Box>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessAdded}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            {edit ? `City Successfully Updated` : `City Successfully Added`}
          </Alert>
        </Snackbar>
      </Stack>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessRemoved}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            City Successfully Deleted
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "5px",
          marginTop: "50px",
          marginRight: "10px",
        }}
      >
        <Button
          sx={{
            "@media (max-width: 600px)": {
              fontSize: "0.6rem",
              backgroundColor: "#2b445a",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              fontSize: "0.8rem",
              backgroundColor: "#2b445a",
            },
            "@media (min-width: 970px)": {
              fontSize: "0.8rem",
              backgroundColor: "#2b445a",
            },
          }}
          variant="contained"
          onClick={() => setOpen(true)}
         
        >
          Add City
        </Button>
      </div>
      <div>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Name
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item._id}
                      >
                        <TableCell align="center" sx={tableHeadStyle}>
                          {item?.name}
                        </TableCell>

                        <TableCell align="center">
                          {
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton onClick={() => {}}>
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  handleEdit(item)
                                }}
                              >
                                <EditNoteIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpenDialog(true)
                                  setDeleteItem(item)
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={tableHeadStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

export default City
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}
