import React, { useEffect, useState } from "react"
import "../../css/customers.css"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../../components/ImageKit/ImageKitCred"
import { IKContext, IKImage, IKUpload } from "imagekitio-react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { UpdateOneCustomer } from "../../common/Services/dbServices"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import CircularProgress from "@mui/material/CircularProgress"

const EditCustomer = ({
  setOpen,
  edit,
  specificCustomer = null,
  refetch,
  setShowSuccessAdded,
  setShowError,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [profileImg, setProfileImg] = useState({})
  const [profileImgLoading, setProfileImgLoading] = useState(false)
  const [checkedActive, setCheckedActive] = React.useState(false)
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
  })

  const handleChangeActive = event => {
    setCheckedActive(event.target.checked)
  }

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const editCustomers = async data => {
    setIsLoading(true)
    try {
      const result = await UpdateOneCustomer({
        item: specificCustomer,
        data,
        profileImg,
        checkedActive,
      })
      if (result) {
        setOpen(false)
        setIsLoading(false)
        refetch()
        setShowSuccessAdded(true)
        setTimeout(() => {
          setShowSuccessAdded(false)
        }, 3000)
      }
    } catch (error) {
      setIsLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  useEffect(() => {
    setValue("firstName", specificCustomer?.firstName)
    setValue("lastName", specificCustomer?.lastName)
    setProfileImg(specificCustomer?.mainImage)
    setCheckedActive(specificCustomer?.metadata?.isActive)
  }, [])

  const onErrorIcon = err => {}

  const onSuccessIcon = res => {
    setProfileImg(res)
    setProfileImgLoading(false)
  }

  const onUploadIcon = res => {
    setProfileImgLoading(true)
  }

  return (
    <div>
      <form>
        <div className="customers-text-field">
          <div className="customers-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="First Name"
              id="fullWidth"
              size="small"
              {...register("firstName", { required: true })}
              error={errors.firstName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.firstName && errors.firstName?.message
                ? errors.firstName?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="customers-text-field-row">
            <TextField
              fullWidth
              label="Last Name"
              id="fullWidth"
              size="small"
              {...register("lastName", { required: true })}
              error={errors.lastName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.lastName && errors.lastName?.message
                ? errors.lastName?.message.toString()
                : null}
            </Typography>
          </div>
        </div>
        <div className="customers-text-field">
          <div className="customers-toggle-div">
            <FormControlLabel
              control={
                <Switch
                  checked={checkedActive}
                  onChange={handleChangeActive}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Active"
              labelPlacement="start"
            />
            <div className="customer-image-choose">
              <h5>Image:</h5>
              {profileImg && profileImg.filePath ? (
                <div
                  style={{
                    width: 35,
                    height: 30,
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <IKImage
                      urlEndpoint={urlEndpoint}
                      path={profileImg.filePath}
                      width="50px"
                      height="50px"
                    />
                    {profileImg.filePath && (
                      <div
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          zIndex: "1",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // setImage(null);
                          setProfileImg(null)
                        }}
                      >
                        <AiOutlineCloseCircle
                          size={16}
                          color="red"
                          style={{
                            position: "absolute",
                            top: -10,
                            right: -10,
                            // color: 'red',
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <IKContext
                  publicKey={publicKey}
                  urlEndpoint={urlEndpoint}
                  authenticationEndpoint={authenticationEndpoint}
                >
                  {profileImgLoading ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <IKUpload
                      onError={onErrorIcon}
                      onSuccess={onSuccessIcon}
                      onUploadStart={onUploadIcon}
                      buttonProps={{ children: "+" }}
                    />
                  )}
                </IKContext>
              )}
            </div>
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(editCustomers)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default EditCustomer
